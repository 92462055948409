import { FiHome, FiEdit, FiUser } from "react-icons/fi";
import { Box, Center, HStack, IconButton, Stack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import Link from "@/components/Link";
import useModalState from "@/hooks/useModalState";
import SearchApartmentModal from "@/components/Search/SearchApartmentModal";
import { Apartment } from "@/apis/api";
import { useAuth } from "@/contexts/AuthContext";
import { useRef } from "react";

const BottomNavigation = () => {
  const { pathname, push } = useRouter();
  const activeStyle = { color: "primary.500" };
  const writeModalHandler = useModalState();
  const { checkAuth } = useAuth();
  const isCreating = useRef(false);

  const handleWrite = () => {
    checkAuth() && writeModalHandler.onOpen();
  };

  const handleApartClick = async (apartment: Apartment) => {
    if (!isCreating.current) {
      isCreating.current = true;
      await push({
        pathname: "/imjang/new",
        query: { apartId: apartment.apartmentId },
      });
      isCreating.current = false;
    }
  };

  return (
    <Box position="fixed" bottom={0} left={0} right={0} background="white">
      <Box
        height="60px"
        zIndex="sticky"
        borderTop="1px solid"
        borderColor="gray.200"
        mb="safeAreaBottom"
      >
        <HStack height="full" justifyContent="space-around">
          <Link href="/" textDecoration="none">
            <Stack spacing={0} alignItems="center">
              <Center {...(pathname === "/" && activeStyle)}>
                <FiHome size={24} />
              </Center>
              <Text textStyle="button4">홈</Text>
            </Stack>
          </Link>
          <Link href="/my" textDecoration="none" auth>
            <Stack spacing={0} alignItems="center">
              <Center {...(pathname === "/my" && activeStyle)}>
                <FiUser size={24} />
              </Center>
              <Text textStyle="button4">프로필</Text>
            </Stack>
          </Link>
        </HStack>
        <Center
          position="absolute"
          top={0}
          left="50%"
          transform="translate(-50%, -25%)"
          zIndex="docked"
          borderRadius="full"
          width="60px"
          height="60px"
          boxShadow="dark-lg"
        >
          <IconButton
            name="home_write"
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="full"
            h="full"
            variant="unstyled"
            fontSize="xl"
            color="white"
            background="primary.500"
            aria-label="write"
            borderRadius="full"
            onClick={handleWrite}
          >
            <FiEdit />
          </IconButton>
        </Center>
      </Box>
      <SearchApartmentModal
        {...writeModalHandler}
        onItemClick={handleApartClick}
      />
    </Box>
  );
};

export default BottomNavigation;
