export class ApiError extends Error {
  code: string;
  constructor(code: string, msg: string) {
    super(msg);

    this.code = code;
    this.name = "ApiError";
  }
}

export enum ApiCode {
  Success = "000",
  InvalidToken = "440",
  NeedAgreement = "441",
}
