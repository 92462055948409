/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Apartment */
export interface Apartment {
  /**
   * 아파트 ID
   * @format int32
   */
  apartmentId?: number;
  /**
   * 건폐율
   * @format int32
   */
  buildingToLandRatio?: number;
  /**
   * 좌표x(경도)
   * @format double
   */
  coordX?: number;
  /**
   * 좌표y(위도)
   * @format double
   */
  coordY?: number;
  /**
   * 용적률
   * @format int32
   */
  floorAreaRatio?: number;
  /**
   * 세대수
   * @format int32
   */
  houseHoldsCount?: number;
  /**
   * 사용승인일
   * @format date-time
   */
  moveInDatetime?: string;
  /** 명칭 */
  name?: string;
}

/** ApartmentList */
export interface ApartmentList {
  apartmentList: Apartment[];
}

/** ApartmentResponse */
export interface ApartmentResponse {
  code: string;
  message: string;
  result?: ApartmentList;
}

/** CheckList */
export interface CheckList {
  /** @format date-time */
  createDateTime?: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  imjangId?: number;
  message?: string;
  /** @format date-time */
  modifyDateTime?: string;
  type?: string;
  value?: string;
}

/** CheckListDeleteRequest */
export interface CheckListDeleteRequest {
  /**
   * @format int32
   * @example 1437
   */
  checkListId?: number;
  /**
   * @format int32
   * @example 1
   */
  userId?: number;
}

/** CheckListDto */
export interface CheckListDto {
  /** @format int32 */
  checkListId: number;
  memo?: string;
  message?: string;
  photos?: PhotoDto[];
  type?: string;
  value?: string;
}

/** CheckListResponse */
export interface CheckListResponse {
  code: string;
  message: string;
  result?: CheckList;
}

/** CheckListUpdateDetailRequest */
export interface CheckListUpdateDetailRequest {
  /** @format int32 */
  checkListId?: number;
  /** @format int32 */
  imjangId?: number;
  memo?: string;
  message?: string;
  photos?: PhotoRequest[];
  type: string;
  value?: string;
}

/** CheckListUpdateValueRequest */
export interface CheckListUpdateValueRequest {
  /** @format int32 */
  checkListId?: number;
  value: string;
}

/** ImjangDto */
export interface ImjangDto {
  apartmentName?: string;
  checkLists?: CheckListDto[];
  comment?: string;
  /** @format date-time */
  imjangDateTime?: string;
  /** @format int32 */
  imjangId: number;
  /** deprecated!! use photos */
  photoUrls?: string[];
  photos?: PhotoDto[];
  /** @format int32 */
  price?: number;
  /** @format int32 */
  userId?: number;
  userNickname?: string;
  userProfileImageUrl?: string;
  /** @format int32 */
  viewCount?: number;
}

/** ImjangInitRequest */
export interface ImjangInitRequest {
  /**
   * @format int32
   * @example 1
   */
  apartmentId?: number;
  /**
   * @format int32
   * @example 1
   */
  userId?: number;
}

/** ImjangListResponse */
export interface ImjangListResponse {
  code: string;
  message: string;
  result?: ImjangDto[];
}

/** ImjangRequest */
export interface ImjangRequest {
  imjangComment: string;
  /** @format date-time */
  imjangDateTime: string;
  /** @format int32 */
  imjangId?: number;
  photos?: PhotoRequest[];
  /** @format int32 */
  price?: number;
}

/** ImjangResponse */
export interface ImjangResponse {
  code: string;
  message: string;
  result?: ImjangDto;
}

/** JwtToken */
export interface JwtToken {
  socialId: string;
  token: string;
}

/** MainBodyDto */
export interface MainBodyDto {
  items: MainBodyItem[];
  templateTitle: string;
  templateType: string;
}

/** MainBodyItem */
export interface MainBodyItem {
  imageUrl?: string;
  imjangDateTime?: string;
  /** @format int32 */
  imjangId?: number;
  title?: string;
  type?: string;
  url?: string;
  userNickName?: string;
  userProfileImageUrl?: string;
}

/** MainHeaderDto */
export interface MainHeaderDto {
  imageUrl?: string;
  /** @format int32 */
  imjangId?: number;
  postUrl?: string;
  title?: string;
  type?: string;
  userNickName?: string;
  userProfileImageUrl?: string;
}

/** MainPage */
export interface MainPage {
  bodies: MainBodyDto[];
  headers: MainHeaderDto[];
}

/** MainPageResponse */
export interface MainPageResponse {
  code: string;
  message: string;
  result?: MainPage;
}

/** ModelAndView */
export interface ModelAndView {
  empty?: boolean;
  model?: object;
  modelMap?: Record<string, object>;
  reference?: boolean;
  status?:
    | "ACCEPTED"
    | "ALREADY_REPORTED"
    | "BAD_GATEWAY"
    | "BAD_REQUEST"
    | "BANDWIDTH_LIMIT_EXCEEDED"
    | "CHECKPOINT"
    | "CONFLICT"
    | "CONTINUE"
    | "CREATED"
    | "DESTINATION_LOCKED"
    | "EXPECTATION_FAILED"
    | "FAILED_DEPENDENCY"
    | "FORBIDDEN"
    | "FOUND"
    | "GATEWAY_TIMEOUT"
    | "GONE"
    | "HTTP_VERSION_NOT_SUPPORTED"
    | "IM_USED"
    | "INSUFFICIENT_SPACE_ON_RESOURCE"
    | "INSUFFICIENT_STORAGE"
    | "INTERNAL_SERVER_ERROR"
    | "I_AM_A_TEAPOT"
    | "LENGTH_REQUIRED"
    | "LOCKED"
    | "LOOP_DETECTED"
    | "METHOD_FAILURE"
    | "METHOD_NOT_ALLOWED"
    | "MOVED_PERMANENTLY"
    | "MOVED_TEMPORARILY"
    | "MULTIPLE_CHOICES"
    | "MULTI_STATUS"
    | "NETWORK_AUTHENTICATION_REQUIRED"
    | "NON_AUTHORITATIVE_INFORMATION"
    | "NOT_ACCEPTABLE"
    | "NOT_EXTENDED"
    | "NOT_FOUND"
    | "NOT_IMPLEMENTED"
    | "NOT_MODIFIED"
    | "NO_CONTENT"
    | "OK"
    | "PARTIAL_CONTENT"
    | "PAYLOAD_TOO_LARGE"
    | "PAYMENT_REQUIRED"
    | "PERMANENT_REDIRECT"
    | "PRECONDITION_FAILED"
    | "PRECONDITION_REQUIRED"
    | "PROCESSING"
    | "PROXY_AUTHENTICATION_REQUIRED"
    | "REQUESTED_RANGE_NOT_SATISFIABLE"
    | "REQUEST_ENTITY_TOO_LARGE"
    | "REQUEST_HEADER_FIELDS_TOO_LARGE"
    | "REQUEST_TIMEOUT"
    | "REQUEST_URI_TOO_LONG"
    | "RESET_CONTENT"
    | "SEE_OTHER"
    | "SERVICE_UNAVAILABLE"
    | "SWITCHING_PROTOCOLS"
    | "TEMPORARY_REDIRECT"
    | "TOO_EARLY"
    | "TOO_MANY_REQUESTS"
    | "UNAUTHORIZED"
    | "UNAVAILABLE_FOR_LEGAL_REASONS"
    | "UNPROCESSABLE_ENTITY"
    | "UNSUPPORTED_MEDIA_TYPE"
    | "UPGRADE_REQUIRED"
    | "URI_TOO_LONG"
    | "USE_PROXY"
    | "VARIANT_ALSO_NEGOTIATES";
  view?: View;
  viewName?: string;
}

/** PhotoDto */
export interface PhotoDto {
  /** @format int32 */
  id?: number;
  manageKey?: string;
  url?: string;
}

/** PhotoRequest */
export interface PhotoRequest {
  manageKey: string;
  url: string;
}

/** ReportResponse */
export interface ReportResponse {
  code: string;
  message: string;
}

/** SearchReportRequest */
export interface SearchReportRequest {
  /**
   * 아파트 상세페이지에서 넘어올 때 아파트 ID
   * @format int32
   */
  apartmentId?: number;
  /** 제보 내용 상세 */
  comment?: string;
  /** 검색페이지에서의 검색 키워드 */
  keyword?: string;
  /**
   * 제보 타입. 0: 기타, 1: 데이터 없음, 2: 데이터 잘못됨
   * @format int32
   */
  type?: number;
}

/** TokenRequest */
export interface TokenRequest {
  email?: string;
  nickname?: string;
  profileImageUrl?: string;
  socialId: string;
  socialToken?: string;
  socialType?: string;
}

/** TokenResponse */
export interface TokenResponse {
  code: string;
  message: string;
  result?: JwtToken;
}

/** UserDto */
export interface UserDto {
  /** @format date-time */
  agreementDateTime?: string;
  email?: string;
  /** @format int32 */
  id?: number;
  nickName?: string;
  profileImageUrl?: string;
  socialType?: string;
}

/** UserResponse */
export interface UserResponse {
  code: string;
  message: string;
  result?: UserDto;
}

/** View */
export interface View {
  contentType?: string;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  HeadersDefaults,
  ResponseType,
} from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "//api.imjanggogo.com",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === "object"
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== "string"
    ) {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title IMJANGGOGO API
 * @version API TOS
 * @license License of API (API License URL)
 * @baseUrl //api.imjanggogo.com
 * @contact admin <admin@imjangogog.com> (imjanggogo.com)
 *
 * 임장고고 API
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  apartment = {
    /**
     * @description 명칭으로 검색
     *
     * @tags apartment-controller
     * @name SearchApartmentUsingGet
     * @summary 아파트 검색
     * @request GET:/apartment/search
     */
    searchApartmentUsingGet: (
      query: {
        /**
         * 아파트 검색 키워드
         * @example "광교"
         */
        keyword: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApartmentResponse, void>({
        path: `/apartment/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 아파트 객체(검색과 동일하게 list 로)
     *
     * @tags apartment-controller
     * @name GetApartmentUsingGet
     * @summary 아파트 id 로 아파트정보 조회
     * @request GET:/apartment/{apartmentId}
     */
    getApartmentUsingGet: (apartmentId: number, params: RequestParams = {}) =>
      this.request<ApartmentResponse, void>({
        path: `/apartment/${apartmentId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  checkList = {
    /**
     * @description 공통 응답
     *
     * @tags check-list-controller
     * @name DeleteCheckListUsingDelete
     * @summary 체크리스트 삭제
     * @request DELETE:/check-list/delete
     */
    deleteCheckListUsingDelete: (
      checkListDeleteRequest: CheckListDeleteRequest,
      params: RequestParams = {}
    ) =>
      this.request<CheckListResponse, void>({
        path: `/check-list/delete`,
        method: "DELETE",
        body: checkListDeleteRequest,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description checkListId 파라미터가 있으면 update, 없으면 insert
     *
     * @tags check-list-controller
     * @name UpdateCheckListDetailUsingPost
     * @summary 체크리스트 추가 및 업데이트
     * @request POST:/check-list/update/detail
     */
    updateCheckListDetailUsingPost: (
      checkListUpdateDetailRequest: CheckListUpdateDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<CheckListResponse, void>({
        path: `/check-list/update/detail`,
        method: "POST",
        body: checkListUpdateDetailRequest,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 공통 응답
     *
     * @tags check-list-controller
     * @name UpdateCheckListValueUsingPost
     * @summary 체크리스트 값만 업데이트
     * @request POST:/check-list/update/value
     */
    updateCheckListValueUsingPost: (
      checkListUpdateValueRequest: CheckListUpdateValueRequest,
      params: RequestParams = {}
    ) =>
      this.request<CheckListResponse, void>({
        path: `/check-list/update/value`,
        method: "POST",
        body: checkListUpdateValueRequest,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  error = {
    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingGet
     * @summary error
     * @request GET:/error
     */
    errorUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingHead
     * @summary error
     * @request HEAD:/error
     */
    errorUsingHead: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "HEAD",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPost
     * @summary error
     * @request POST:/error
     */
    errorUsingPost: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPut
     * @summary error
     * @request PUT:/error
     */
    errorUsingPut: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingDelete
     * @summary error
     * @request DELETE:/error
     */
    errorUsingDelete: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingOptions
     * @summary error
     * @request OPTIONS:/error
     */
    errorUsingOptions: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "OPTIONS",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPatch
     * @summary error
     * @request PATCH:/error
     */
    errorUsingPatch: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "PATCH",
        format: "json",
        ...params,
      }),
  };
  imjang = {
    /**
     * @description 임장 객체 리스트(Imjang)
     *
     * @tags imjang-controller
     * @name GetListByApartIdUsingGet
     * @summary 특정 아파트에 대한 관리자 계정 작성 임장리스트 조회
     * @request GET:/imjang/apartment/{apartmentId}
     */
    getListByApartIdUsingGet: (
      apartmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<ImjangListResponse, void>({
        path: `/imjang/apartment/${apartmentId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags imjang-controller
     * @name DeleteImjangUsingDelete
     * @summary 임장 삭제 api
     * @request DELETE:/imjang/delete/{imjangId}
     */
    deleteImjangUsingDelete: (imjangId: number, params: RequestParams = {}) =>
      this.request<ImjangResponse, void>({
        path: `/imjang/delete/${imjangId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * @description 임장 기본 객체 (Imjang)
     *
     * @tags imjang-controller
     * @name InitImjangUsingPost
     * @summary 임장 초기 생성을 위한 api
     * @request POST:/imjang/init
     */
    initImjangUsingPost: (
      request: ImjangInitRequest,
      params: RequestParams = {}
    ) =>
      this.request<ImjangResponse, void>({
        path: `/imjang/init`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 임장 객체 (Imjang)
     *
     * @tags imjang-controller
     * @name UpdateImjangUsingPost
     * @summary 임장 업데이트를 위한 api
     * @request POST:/imjang/update
     */
    updateImjangUsingPost: (
      imjangRequest: ImjangRequest,
      params: RequestParams = {}
    ) =>
      this.request<ImjangResponse, void>({
        path: `/imjang/update`,
        method: "POST",
        body: imjangRequest,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 임장 객체 리스트(Imjang)
     *
     * @tags imjang-controller
     * @name GetListByUserIdUsingGet
     * @summary 유저 별 임장 리스트 조회 api
     * @request GET:/imjang/user
     */
    getListByUserIdUsingGet: (params: RequestParams = {}) =>
      this.request<ImjangListResponse, void>({
        path: `/imjang/user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description 임장 객체 (Imjang)
     *
     * @tags imjang-controller
     * @name GetImjangUsingGet
     * @summary 임장id로 체크리스트 통합 조회. 권한있는 유저만 조회가능
     * @request GET:/imjang/{imjangId}
     */
    getImjangUsingGet: (imjangId: number, params: RequestParams = {}) =>
      this.request<ImjangResponse, void>({
        path: `/imjang/${imjangId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  mainPage = {
    /**
     * @description 사용자별 메인페이지
     *
     * @tags main-page-controller
     * @name MainPageUsingGet
     * @summary 메인페이지 조회
     * @request GET:/main-page/
     */
    mainPageUsingGet: (params: RequestParams = {}) =>
      this.request<MainPageResponse, void>({
        path: `/main-page/`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  searchReport = {
    /**
     * No description
     *
     * @tags report-controller
     * @name SaveSearchReportUsingPost
     * @summary 검색페이지 제보
     * @request POST:/searchReport
     */
    saveSearchReportUsingPost: (
      request: SearchReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<ReportResponse, void>({
        path: `/searchReport`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  token = {
    /**
     * No description
     *
     * @tags token-controller
     * @name DeleteTokenUsingDelete
     * @summary 사용자 임장고곡 토큰 조회
     * @request DELETE:/token/delete
     */
    deleteTokenUsingDelete: (params: RequestParams = {}) =>
      this.request<TokenResponse, void>({
        path: `/token/delete`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags token-controller
     * @name GetTokenUsingPost
     * @summary 사용자 임장고곡 토큰 조회
     * @request POST:/token/get
     */
    getTokenUsingPost: (
      tokenRequest: TokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<TokenResponse, void>({
        path: `/token/get`,
        method: "POST",
        body: tokenRequest,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user-controller
     * @name RegisterAgreeUsingPost
     * @summary 사용자 약관 동의
     * @request POST:/user/agree
     */
    registerAgreeUsingPost: (params: RequestParams = {}) =>
      this.request<UserResponse, void>({
        path: `/user/agree`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteUserUsingDelete
     * @summary 사용자 탈퇴
     * @request DELETE:/user/delete
     */
    deleteUserUsingDelete: (params: RequestParams = {}) =>
      this.request<UserResponse, void>({
        path: `/user/delete`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserUsingGet
     * @summary 사용자 조회
     * @request GET:/user/get
     */
    getUserUsingGet: (params: RequestParams = {}) =>
      this.request<UserResponse, void>({
        path: `/user/get`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
