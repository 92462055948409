import { isDev } from "@/utils/config";
import { Center, IconButton } from "@chakra-ui/react";
import { FiRepeat } from "react-icons/fi";

const Button = () => {
  const handleReload = () => window.location.reload();

  return (
    <Center
      position="fixed"
      w="32px"
      h="32px"
      borderRadius="full"
      right={5}
      bottom={5}
      zIndex="modal"
    >
      <IconButton
        onClick={handleReload}
        aria-label="reload"
        icon={<FiRepeat />}
      />
    </Center>
  );
};

const ReloadButton = isDev ? Button : () => null;

export default ReloadButton;
