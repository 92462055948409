import { Apartment } from "@/apis/api";
import SearchApartment from "@/components/Search/SearchApartment";
import useSearch from "@/hooks/useSearch";
import { searchApartmentQuery } from "@/query/apartment";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FiSearch } from "react-icons/fi";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onItemClick: (apartment: Apartment) => void;
};

const SearchApartmentModal = ({ isOpen, onClose, onItemClick }: Props) => {
  const { input, setInput, query } = useSearch("");
  const canSearch = input.length > 1;

  const { data, error, isLoading } = useQuery({
    ...searchApartmentQuery.query({ keyword: query }),
    enabled: query.length > 1,
    useErrorBoundary: false,
  });

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="full"
      scrollBehavior="inside"
      motionPreset="none"
      variant="safeAreaTop"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">아파트 검색</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} h="full">
            <InputGroup position="sticky">
              <InputLeftElement>
                <FiSearch />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="OOO 아파트"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </InputGroup>
            <Box overflow="auto">
              {canSearch ? (
                <SearchApartment
                  query={query}
                  isLoading={isLoading || input !== query}
                  error={error}
                  data={data?.result?.apartmentList}
                  onItemClick={onItemClick}
                />
              ) : (
                <Box pt={10}>
                  <Text textAlign="center">
                    체크리스트를 작성할 아파트를 검색해주세요.
                  </Text>
                </Box>
              )}
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchApartmentModal;
