import { Api } from "@/apis/api";
import { isBrowser } from "@/constants/ssr";
import { ApiError, ApiCode } from "@/utils/error";
import { captureException } from "@sentry/nextjs";

export const instance = new Api({
  baseURL: "https://api.imjanggogo.com",
  withCredentials: true,
});

instance.instance.interceptors.response.use(
  (response) => {
    // TODO: API 오류처리
    if (response.data && response.data.code !== ApiCode.Success) {
      if (
        isBrowser &&
        response.data.code === ApiCode.NeedAgreement &&
        !window.location.pathname.startsWith("/user/agreement")
      ) {
        window.location.href =
          "/user/agreement?redirect=" +
          encodeURIComponent(window.location.href);
      }

      const apiError = new ApiError(response.data.code, response.data.message);

      captureException(apiError, {
        level: "warning",
        extra: {
          config: response?.config,
          cookie: response?.config?.headers?.cookie,
        },
      });

      throw apiError;
    }

    return response;
  },
  (e) => {
    captureException(e, {
      extra: {
        config: e?.config,
        cookie: e?.config?.headers?.cookie,
      },
    });

    return Promise.reject(e);
  }
);
