import { instance } from "@/apis/instance";
import { createQuery } from "@/utils/reactQuery";

export const searchApartmentQuery = createQuery(
  "searchApartment",
  instance.apartment.searchApartmentUsingGet
);

export const apartmentQuery = createQuery(
  "apartment",
  instance.apartment.getApartmentUsingGet
);
