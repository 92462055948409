import { appScheme, WebviewOption } from "@/constants/appScheme";
import { useAuth } from "@/contexts/AuthContext";
import {
  type LinkProps as ChakraLinkProps,
  Link as ChakraLink,
} from "@chakra-ui/react";
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import { HOST } from "@/constants/config";
import { formatUrl, Url } from "@/utils/url";

const isExternal = (href: Url) => {
  const url = formatUrl(href);

  return url?.startsWith("http") && !url?.startsWith(HOST.app);
};

type Props = Omit<ChakraLinkProps, "href"> &
  NextLinkProps & { auth?: boolean; webviewOption?: WebviewOption };

const Link = ({ href, auth, ...props }: Props) => {
  const { isLoggedIn } = useAuth();
  const external = isExternal(href);

  return (
    <ChakraLink
      textDecoration="underline"
      as={NextLink}
      {...props}
      // @ts-expect-error href 타입 mismatch. 어차피 string
      href={
        auth && !isLoggedIn
          ? appScheme.login(href)
          : external
          ? appScheme.webview(href)
          : href
      }
    />
  );
};

export default Link;
