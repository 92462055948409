import BottomNavigation from "@/components/BottomNavigation";
import Header, { HeaderProps } from "@/components/Header";
import { Container, ContainerProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type TProps = PropsWithChildren<{
  header?: HeaderProps | false;
  bottomNavigation?: boolean;
}> &
  ContainerProps;

const Layout = ({
  children,
  header,
  bottomNavigation,
  ...containerProps
}: TProps) => {
  return (
    <>
      {header !== false && <Header {...header} />}
      <Container
        pt={5}
        pb={10}
        {...(bottomNavigation && {
          pb: "calc(60px + env(safe-area-inset-bottom, constant(safe-area-inset-bottom)))",
        })}
        {...containerProps}
      >
        {children}
        {bottomNavigation && <BottomNavigation />}
      </Container>
    </>
  );
};

export default Layout;
