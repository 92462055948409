import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FiChevronLeft, FiMoreVertical } from "react-icons/fi";
import { useWindowScroll } from "react-use";

export type TMenu = {
  label: string;
  onClick?: () => void;
};

export type HeaderProps = {
  title?: React.ReactNode;
  menus?: TMenu[];
  right?: React.ReactNode;
};

const Header = ({ title, menus, right }: HeaderProps) => {
  const { back } = useRouter();
  const { y } = useWindowScroll();

  return (
    <Box
      as="header"
      zIndex="sticky"
      position="sticky"
      top={0}
      left={0}
      right={0}
      w="full"
      background="white"
      px={1}
      {...(y > 0 && { boxShadow: "md" })}
    >
      <HStack spacing={1} minH="60px" pt="safeAreaTop">
        <IconButton
          variant="unstyled"
          aria-label="back"
          size="lg"
          icon={<FiChevronLeft size={24} />}
          px={4}
          display="flex"
          alignItems="center"
          onClick={back}
        />
        {title && (
          <Box w="full" as="h1" fontSize="xl" fontWeight="bold" noOfLines={1}>
            {title}
          </Box>
        )}
        <Spacer />
        {right}
        {menus && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FiMoreVertical />}
              variant="ghost"
              size="lg"
            />
            <MenuList>
              {menus.map(({ label, onClick }, i) => (
                <MenuItem key={i} onClick={onClick}>
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Box>
  );
};

export default Header;
