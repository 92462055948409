import { SearchReportRequest } from "@/apis/api";
import { instance } from "@/apis/instance";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

export enum ReportType {
  Etc,
  NoData,
  WrongData,
}

const reportTypeList = [
  {
    label: "데이터가 없어요",
    value: ReportType.NoData,
  },
  {
    label: "데이터가 잘못되었어요",
    value: ReportType.WrongData,
  },
  {
    label: "기타",
    value: ReportType.Etc,
  },
];

type Props = {
  title?: string;
  defaultType?: ReportType;
  reportData?: Pick<SearchReportRequest, "apartmentId" | "keyword">;
} & Omit<ModalProps, "children">;

const ReportModal = ({
  title,
  reportData,
  defaultType = ReportType.NoData,
  ...props
}: Props) => {
  const [reportType, setReportType] = useState(defaultType);
  const [comment, setComment] = useState("");
  const toast = useToast();

  const handleReport = async () => {
    try {
      await instance.searchReport.saveSearchReportUsingPost({
        type: reportType,
        comment,
        ...reportData,
      });
      toast({
        description: "제보해주셔서 감사해요. 빠르게 반영할게요!",
      });
      props.onClose();
    } catch (e) {
      toast({
        status: "error",
        description: "일시적인 오류가 발생했어요. 잠시 후 다시 시도해주세요.",
      });
    }
  };

  return (
    <Modal {...props} motionPreset="none">
      <ModalOverlay />
      <ModalContent w="90%">
        <ModalHeader>{title || "제보하기"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <RadioGroup
              onChange={(v) => setReportType(Number(v) as ReportType)}
              value={reportType}
            >
              <Stack spacing={1}>
                {reportTypeList.map(({ label, value }) => (
                  <Radio key={value} value={value}>
                    {label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            {reportType === ReportType.Etc && (
              <Textarea
                placeholder="기타 내용을 입력해주세요."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="primary" w="full" onClick={handleReport}>
            제보하기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;
