import { formatUrl as formatNextUrl } from "next/dist/shared/lib/router/utils/format-url";
import { UrlObject } from "url";

export type Url = string | UrlObject;

export const formatUrl = (url?: Url) => {
  if (typeof url === "string" || !url) {
    return url;
  }
  return formatNextUrl(url);
};
