import { instance } from "@/apis/instance";
import { appScheme } from "@/constants/appScheme";
import { KAKAO_REDIRECT_URI } from "@/constants/config";
import { isBrowser } from "@/constants/ssr";
import { TUser } from "@/types/user";
import { ApiCode, ApiError } from "@/utils/error";
import axios from "axios";
import { useRouter } from "next/router";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UrlObject } from "url";

type TAuthContext = (
  | {
      isLoggedIn: false;
      user: undefined;
    }
  | { isLoggedIn: true; user: TUser }
) & {
  checkAuth: (redirectUrl?: string) => boolean;
  logout: () => void;
  reload: () => Promise<void>;
};

const AuthContext = createContext<TAuthContext>({
  user: undefined,
  isLoggedIn: false,
  checkAuth: () => false,
  logout: () => undefined,
  reload: () => Promise.resolve(),
});

export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({
  children,
  user: userProps,
}: PropsWithChildren<{ user: TUser | undefined }>) => {
  const { query, replace } = useRouter();
  const [user, setUser] = useState<TUser | undefined>(userProps);
  const isLoggedIn = !!user;

  const checkAuth = useCallback(
    (redirectUrl?: string | UrlObject) => {
      if (!isBrowser) {
        return false;
      }

      if (!isLoggedIn) {
        // TODO: 웹 개발용 로그인
        if (query?.webLogin) {
          window.Kakao.Auth.authorize({
            redirectUri: KAKAO_REDIRECT_URI,
          });
          return false;
        }

        window.location.href = appScheme.login(redirectUrl);
        return false;
      }

      return true;
    },
    [isLoggedIn]
  );

  const handleLogout = useCallback(() => {
    Promise.all([
      instance.token.deleteTokenUsingDelete(),
      axios.post("/api/user/logout"),
    ])
      .then(() => {
        window.location.href = "/";
      })
      .catch((e) => console.log(e));
  }, []);

  const handleReload = () => {
    return instance.user
      .getUserUsingGet()
      .then((data) => {
        if (data.code === ApiCode.Success && data.result) {
          setUser(data.result as TUser);
        }
      })
      .catch((e: any) => {
        if (e instanceof ApiError && e.code === ApiCode.NeedAgreement) {
          replace({
            pathname: "/user/agreement",
            query: {
              redirectUrl: "/",
            },
          });
        }
      });
  };

  useEffect(() => {
    if (!user) {
      handleReload();
    }
  }, []);

  useEffect(() => {
    userProps && setUser(userProps);
  }, [userProps]);

  useEffect(() => {
    if (user) {
      window.dataLayer?.push({
        user_id: user.id,
        user_name: user.nickName,
        user_email: user.email,
      });
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        ...(user
          ? { user, isLoggedIn: true }
          : { user: undefined, isLoggedIn: false }),
        checkAuth,
        logout: handleLogout,
        reload: handleReload,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
