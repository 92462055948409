import ErrorBase from "@/components/Error/ErrorBase";
import Loading from "@/components/Loading";
import React, { PropsWithChildren } from "react";

type TProps = {
  loading?: boolean;
  error?: unknown;
  onRetry?: () => unknown | Promise<unknown>;
};

function SectionRenderer({
  loading,
  error,
  onRetry,
  children,
}: PropsWithChildren<TProps>) {
  const handleRetry = async () => {
    try {
      await onRetry?.();
    } catch (e) {}
  };

  if (loading) {
    return <Loading />;
  }

  if (!error) {
    return <>{children}</>;
  }

  return <ErrorBase onReset={handleRetry} />;
}

export default SectionRenderer;
