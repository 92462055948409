import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  semanticTokens: {
    colors: {
      text: {
        default: "gray.900",
        _dark: "gray.50",
      },
      background: {
        default: "white",
        _dark: "gray.800",
      },
    },
  },
  space: {
    safeAreaTop: "env(safe-area-inset-top, constant(safe-area-inset-top))",
    safeAreaBottom:
      "env(safe-area-inset-bottom, constant(safe-area-inset-bottom))",
  },
  colors: {
    primary: {
      50: "#e6ecff",
      100: "#bac6fb",
      200: "#8ea0f2",
      300: "#617aea",
      400: "#3554e3",
      500: "#1E3FDA",
      600: "#142d9e",
      700: "#0b2072",
      800: "#041347",
      900: "#00051d",
    },
    secondary: {
      50: "#ebecf8",
      100: "#cdceed",
      200: "#acafe1",
      300: "#8c8fd4",
      400: "#7474ca",
      500: "#5f5ac0",
      600: "#5852b5",
      700: "#5047a9",
      800: "#483d9d",
      900: "#3c2a87",
    },
    onPrimary: "#FFFFFF",
    onSecondary: "#FFFFFF",
    textPrimary: "#2D2F2F",
    textSecondary: "#5D5F60",
  },
  fontSizes: {
    d1: "30px",
    d2: "24px",
    h1: "20px",
    h2: "18px",
    b1: "16px",
    b2: "14px",
    p1: "15px",
    p2: "14px",
    p3: "13px",
    c1: "12px",
    c2: "11px",
  },
  layerStyles: {
    card: {
      bg: "white",
      borderRadius: 10,
    },
    guide: {
      bg: "white",
      color: "textDisable",
      borderRadius: "10px",
      borderColor: "textDisable",
      borderWidth: "1px",
      borderStyle: "dashed",
    },
  },
  textStyles: {
    h1: {
      fontWeight: "bold",
      fontSize: "h1",
      lineHeight: "22px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "h2",
      lineHeight: "22px",
    },
    st1: {
      fontWeight: "bold",
      fontSize: "h2",
      lineHeight: "22px",
    },
    st2: {
      fontWeight: "bold",
      fontSize: "b1",
      lineHeight: "22px",
    },
    b1: {
      fontWeight: "normal",
      fontSize: "b1",
      lineHeight: "22px",
    },
    b2: {
      fontWeight: "normal",
      fontSize: "b2",
      lineHeight: "22px",
    },
    c1: {
      fontWeight: "normal",
      fontSize: "c1",
      lineHeight: "22px",
    },
    c2: {
      fontWeight: "normal",
      fontSize: "c2",
      lineHeight: "22px",
    },
    button1: {
      fontWeight: "bold",
      fontSize: "h3",
      lineHeight: "22px",
    },
    button2: {
      fontWeight: "bold",
      fontSize: "p1",
      lineHeight: "22px",
    },
    button3: {
      fontWeight: "bold",
      fontSize: "p2",
      lineHeight: "22px",
    },
    button4: {
      fontWeight: "bold",
      fontSize: "c1",
      lineHeight: "17px",
    },
  },
  shadows: { outline: "primary.500" },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
          outline: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "b2",
        color: "textPrimary",
      },
    },
    FormHelperText: {
      defaultProps: {
        fontSize: "c1",
      },
    },
    Drawer: {
      parts: ["body"],
      baseStyle: {
        body: {
          pb: "calc(env(safe-area-inset-bottom) + 20px)",
        },
      },
    },
    Modal: {
      parts: ["closeButton, header"],
      baseStyle: {
        header: {
          mt: "10px",
        },
        closeButton: {
          mt: "10px",
        },
      },
      variants: {
        safeAreaTop: {
          header: {
            pt: "safeAreaTop",
          },
          closeButton: {
            top: "safeAreaTop",
          },
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "primary.500",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "primary.500",
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "primary",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "primary",
      },
    },
  },
  styles: {
    global: {
      "html, body": {
        color: "text",
        background: "background",
      },
      [`input,
      textarea,
      button,
      select`]: {
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
    },
  },
});

export default theme;
