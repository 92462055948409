import * as React from "react";
import { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/styles/theme";
import { notoSansKr } from "@/styles/font";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NextPage } from "next";
import ReloadButton from "@/components/Dev/ReloadButton";
import Head from "next/head";
import GlobalErrorBoundary from "@/components/Error/GlobalErrorBoundary";
import Script from "next/script";
import { isDev } from "@/utils/config";
import AuthContextProvider from "@/contexts/AuthContext";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

export default function MyApp(
  props: AppProps & {
    Component: NextPageWithLayout;
  }
) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 15000,
            retry: false,
            refetchOnWindowFocus: false,
            useErrorBoundary: true,
          },
        },
      })
  );
  const { Component, pageProps } = props;

  // TODO: 추후 다크모드 대응
  // const colorModeManager =
  //   typeof cookie === "string"
  //     ? cookieStorageManagerSSR(cookie)
  //     : localStorageManager;
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
      </Head>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      {!isDev && (
        <>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`  
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-KFWWZQL');
            `}
          </Script>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-2VJ3NV3Z2C"
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-2VJ3NV3Z2C');
        `}
          </Script>
        </>
      )}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ChakraProvider
            theme={{
              ...theme,
              fonts: {
                body: notoSansKr.style.fontFamily,
                heading: notoSansKr.style.fontFamily,
              },
            }}
          >
            <GlobalErrorBoundary>
              <AuthContextProvider user={pageProps?.user}>
                {getLayout(<Component {...pageProps} />)}
              </AuthContextProvider>
            </GlobalErrorBoundary>
            <ReloadButton />
          </ChakraProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
