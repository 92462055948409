import { useRouter } from "next/router";
import { useEffect, useId, useState } from "react";

// query state를 가지고 처리할 수도 있지만 너무 느림. 직접 state 가지고 있고 제어하는 방향으로 처리
const useModalState = (
  {
    key,
    defaultState = false,
  }: {
    key?: string;
    defaultState?: boolean;
  } = { defaultState: false }
) => {
  const ssrId = useId();
  const { query, back, pathname, push, beforePopState } = useRouter();
  const modalKey = key || ssrId;
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
      push({ pathname, query: { ...query, [modalKey]: true } }, undefined, {
        shallow: true,
        scroll: false,
      });

      beforePopState(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (!searchParams.get(modalKey)) {
          setIsOpen(false);
        }
        return true;
      });
    }
  };

  const onClose = () => {
    isOpen && back();
  };

  useEffect(() => {
    defaultState && setIsOpen(true);
  }, []);

  return { isOpen, onOpen, onClose };
};

export default useModalState;
