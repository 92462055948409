import { HOST } from "@/constants/config";
import { formatUrl, Url } from "@/utils/url";

const baseScheme = "imjanggogoapp://";
const defaultWebviewOption = {
  showAppbar: "true",
  light: "true",
};

export type WebviewOption = {
  showAppbar?: boolean;
  appbarTitle?: string;
  light?: boolean;
};

export const appScheme = {
  login: (redirectUrl?: Url) => {
    const url = formatUrl(redirectUrl);

    return `${baseScheme}login?url=${HOST.app}${url || "/"}`;
  },
  webview: (url: Url, option?: WebviewOption) => {
    const searchParams = new URLSearchParams({
      url: formatUrl(url),
      ...defaultWebviewOption,
      ...option,
    } as Record<string, string>);

    return `${baseScheme}webview?${searchParams.toString()}`;
  },
  camera: (callback: string) => {
    return `${baseScheme}camera?callback=${callback}`;
  },
  album: (callback: string) => {
    return `${baseScheme}album?callback=${callback}`;
  },
};
