import { Button, Center, Stack, Text } from "@chakra-ui/react";

type Props = {
  onReset?: () => void;
};

const ErrorBase = ({ onReset }: Props) => {
  return (
    <Center pt={20}>
      <Stack spacing={10} textAlign="center">
        <Text textStyle="h1">에러가 발생했어요.</Text>
        <Button colorScheme="primary" onClick={() => onReset?.()}>
          다시 시도하기
        </Button>
      </Stack>
    </Center>
  );
};

export default ErrorBase;
