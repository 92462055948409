import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

const AUTOCOMPLETE_DEBOUNCE_DELAY = 500;

const useSearch = (
  initialKeyword: string,
  debounceDelay = AUTOCOMPLETE_DEBOUNCE_DELAY
) => {
  const [input, setInput] = useState(initialKeyword);
  const [query, setQuery] = useState(input);
  const [, cancel] = useDebounce(() => setQuery(input), debounceDelay, [input]);

  const reset = useCallback(() => {
    cancel();
    setInput("");
    setQuery("");
  }, [cancel]);

  return {
    input,
    setInput,
    query,
    reset,
  };
};

export default useSearch;
