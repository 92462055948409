import { Apartment } from "@/apis/api";
import ReportModal from "@/components/Modal/ReportModal";
import SectionRenderer from "@/components/SectionRenderer";
import useModalState from "@/hooks/useModalState";
import { Box, Button, Center, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";

type Props = {
  query: string;
  isLoading: boolean;
  error: unknown;
  data: Apartment[] | undefined;
  onItemClick: (apartment: Apartment) => void;
};

const SearchApartment = ({
  query,
  isLoading,
  error,
  data,
  onItemClick,
}: Props) => {
  const { isOpen, onClose, onOpen } = useModalState();

  useEffect(() => {
    if (query && data?.length === 0) {
      window.dataLayer?.push({
        event: "search_no_result",
        searchKeyword: query,
      });
    }
  }, [query, data]);

  return (
    <>
      <SectionRenderer loading={isLoading} error={error}>
        {data && (
          <Stack spacing={0}>
            {data.length === 0 ? (
              <Center textAlign="center">
                <Stack spacing={5}>
                  <Text>
                    검색 결과가 존재하지 않아요.
                    <br />
                    제보해주시면 빠르게 반영할게요!
                  </Text>
                  <Box>
                    <Button colorScheme="primary" onClick={onOpen}>
                      제보하기
                    </Button>
                  </Box>
                </Stack>
              </Center>
            ) : (
              data.map((apartment) => (
                <Button
                  key={apartment.apartmentId}
                  variant="unstyled"
                  textAlign="left"
                  onClick={() => onItemClick(apartment)}
                >
                  <Text textStyle="b1">{apartment.name}</Text>
                </Button>
              ))
            )}
          </Stack>
        )}
      </SectionRenderer>
      <ReportModal
        title={query}
        reportData={{
          keyword: query,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default SearchApartment;
