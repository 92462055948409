import ErrorBase from "@/components/Error/ErrorBase";
import Layout from "@/components/Layout";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const { asPath } = useRouter();
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      resetKeys={[asPath]}
      fallbackRender={({ resetErrorBoundary }) => (
        <Layout>
          <ErrorBase onReset={resetErrorBoundary} />
        </Layout>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
