import { RequestParams } from "@/apis/api";

type ExtractParams<T> = T extends [...infer Params, RequestParams?]
  ? Params
  : never;

export const createQuery = <
  TBaseKey extends string,
  TParamWithConfig extends [...any, RequestParams?],
  TData = any
>(
  baseKey: TBaseKey,
  api: (...params: TParamWithConfig) => Promise<TData>
) => {
  const keyGenerator = (...params: ExtractParams<TParamWithConfig>) => {
    if (params.length === 0) {
      return [baseKey];
    }

    return [baseKey, ...params];
  };

  return {
    baseKey,
    key: keyGenerator,
    query: (...paramWithConfig: TParamWithConfig) => {
      const maybeConfig = paramWithConfig[paramWithConfig.length - 1];
      // TODO: requestParam은 항상 optional이라 count 안쳐짐
      const hasConfig =
        api.length < paramWithConfig.length && maybeConfig !== null;

      const params = (
        hasConfig ? paramWithConfig.slice(0, -1) : paramWithConfig
      ) as ExtractParams<TParamWithConfig>;

      return {
        queryKey: keyGenerator(...params),
        queryFn: () => api(...paramWithConfig),
      };
    },
  };
};
